import React from 'react';

interface HomeListingProps {
	title: string;
	mainImage: string;
	groundFloorImage: string;
	firstFloorImage: string;
	altTextMain: string;
	altTextFloor: string;
}

const HomeListing: React.FC<HomeListingProps> = ({
	title,
	mainImage,
	groundFloorImage,
	firstFloorImage,
	altTextMain,
	altTextFloor,
}) => {
	return (
		<div>
			<div className="row mt20">
				<div className="col-lg-12">
					<h4 className="list-title">{title}</h4>
					<img
						className="w-100"
						src={mainImage}
						alt={altTextMain}
					/>
				</div>
			</div>
			<div className="row mt20">
				<div className="col-lg-6">
					<div className="listing-style1 style12">
						<div className="list-thumb">
							<img
								className="w-100"
								src={groundFloorImage}
								alt={altTextFloor}
							/>
						</div>
					</div>
				</div>
				<div className="col-lg-6">
					<div className="listing-style1 style12">
						<div className="list-thumb">
							<img
								className="w-100"
								src={firstFloorImage}
								alt={altTextFloor}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HomeListing;
