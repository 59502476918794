import useMetaTags from './metatags';
import HomeListing from './homeListing';

function NewHomes() {
	useMetaTags(
		'New Homes For Sale In Clacton | Blake &amp; Thickbroom',
		'If You&#039;re Looking For New Homes In Clacton-on-Sea, Essex, Blake &amp; Thickbroom Is One Of The Region&#039;s Leading New Build Estate Agencies'
	);
	return (
		<div>
			<section className="breadcumb-newhomes p-0">
				<div className="container">
					<div className="row">
						<div className="col-lg-6 mt50">
							<div className="breadcumb-style1">
								<h2 className="title">Land & New Home Developments</h2>
								<p>
									With an extensive history and proven experience throughout
									Tendring, our services are built upon solid foundations.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="our-about pb90">
				<div className="container">
					<div
						className="row wow fadeInUp"
						data-wow-delay="100ms">
						<div className="col-lg-6">
							<h2>New Developments</h2>
							<div
								className="row wow fadeInUp"
								data-wow-delay="100ms">
								<div className="col-lg-12">
									<div className="about-page-img">
										<img
											className="w-100"
											src="/images/newhomes/newhomes1.jpg"
											alt="New Developments"
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<p className="text mb25">
								Selling a new development quickly and efficiently takes skill,
								great marketing, and experience.
							</p>
							<p className="text mb25">
								Blake and Thickbroom offer all of these qualities to our clients
								looking to sell their new build properties and land in Clacton &
								Tendring!
							</p>
							<p className="text mb25">
								With extensive marketing, we can display your properties to
								buyers throughout the UK - which has so far given us unrivalled
								success when it comes to new home developments.
							</p>
							<p className="text mb25">
								Since being established in the year 2000, Blake and Thickbroom
								have built strong relationships with house builders and land
								developers in Clacton and Tendring alike. This has made us one
								of the most trusted agents in Tendring when it comes to selling
								land and new homes in Clacton.{' '}
							</p>
							<p className="text mb25">
								We have worked with a range of developers both nationally and
								locally, both big and small.
							</p>
							<p className="text mb25">
								Whatever the size of the portfolio, our team will give it the
								care, attention and expertise that it needs to get the job done.
							</p>
						</div>
					</div>
				</div>
			</section>
			<section className="our-about pb90">
				<div className="container">
					<div
						className="row wow fadeInUp"
						data-wow-delay="100ms">
						<div className="col-lg-6">
							<p className="text mb25">
								Blake and Thickbroom have been successfully involved in selling
								many local developments including St Johns Gardens, Highlands
								Park, and the award-winning Sherwood Oaks.
							</p>
							<p className="text mb25">
								No matter what stage your development is at, the Blake and
								Thickbroom team can offer advice drawing from over 125 years of
								combined experience. We can give you advice on planning
								permission applications and help you bring your development
								plans forward into reality.
							</p>
							<p className="text mb25">
								We can also work with you to start selling your homes off-plan,
								which will speed up the process of getting your homes filled as
								soon as each phase is completed. Take a look at our current new
								developments with many houses in Clacton & Tendring for sale. If
								you're looking to sell your project, contact us today and get
								started!
							</p>
						</div>
						<div className="col-lg-6">
							<h4>EXPERIENCE</h4>
							<h2>Proven Experience in Successful Development Sales</h2>
							<div
								className="row wow fadeInUp"
								data-wow-delay="100ms">
								<div className="col-lg-12">
									<div className="about-page-img">
										<img
											className="w-100"
											src="/images/newhomes/newhomes2.jpg"
											alt="New Developments"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>

					<hr className="orange mt40 mb40" />
					<h2>WELCOME TO CROSSWAYS GARDENS</h2>
					<div className="row wow fadeInUp">
						<p>
							Crossways Gardens is a private development of 21 beautifully
							designed 3 & 4 bedroom homes nestled in the village of Little
							Clacton.
						</p>
						<p>
							Offering tranquil living, each home is finished to the highest
							standard with stylish fixtures and fittings. You can expect
							spacious, open plan kitchen diners with separate lounges and
							en-suites to master bedrooms making the perfect family home.
						</p>
					</div>
					<div className="row mt20">
						<div className="col-lg-12">
							<div className="listing-style1 style12">
								<div className="list-thumb">
									<img
										className="w-100"
										src="/images/newhomes/CrosswaysGarden.jpg"
										alt="CROSSWAYS GARDENS"
									/>
								</div>
							</div>
						</div>
					</div>

					<HomeListing
						title="The Gardenia - 4 Bedroom Home"
						mainImage="/images/newhomes/TheGardeniaPlot12.jpg"
						groundFloorImage="/images/newhomes/GROUNDFLOORTHEGARDENIA.jpg"
						firstFloorImage="/images/newhomes/FIRSTFLOORTHEGARDENIA.jpg"
						altTextMain="The Gardenia"
						altTextFloor="The Gardenia Floor Plan"
					/>

					<HomeListing
						title="The Jasmine - 4 Bedroom Home"
						mainImage="/images/newhomes/TheJasminePlot11.jpg"
						groundFloorImage="/images/newhomes/TheJasminefp0.jpg"
						firstFloorImage="/images/newhomes/TheJasminefp1.jpg"
						altTextMain="The Jasmine"
						altTextFloor="The Jasmine Floor Plan"
					/>

					<HomeListing
						title="The Lavender - 3 Bedroom Home"
						mainImage="/images/newhomes/TheLavenderPlot2.jpg"
						groundFloorImage="/images/newhomes/GROUNDFLOORTHELAVENDER.jpg"
						firstFloorImage="/images/newhomes/FIRSTFLOORTHELAVENDER.jpg"
						altTextMain="The Lavender"
						altTextFloor="The Lavender Floor Plan"
					/>

					<HomeListing
						title="The Wisteria - 4 Bedroom Home"
						mainImage="/images/newhomes/TheWisteriaPlot6.jpg"
						groundFloorImage="/images/newhomes/GROUNDFLOORtHEWISTERIA.jpg"
						firstFloorImage="/images/newhomes/FIRSTFLOORTHEWISTERIA.jpg"
						altTextMain="The Wisteria"
						altTextFloor="The Wisteria Floor Plan"
					/>

					<hr className="orange mt40 mb40" />
					<h2>Thorpe Hall Gardens </h2>
					<h3>THORPE-LE-SOKEN</h3>
					<div className="row wow fadeInUp">
						<p>
							Burfoot Homes are excited to release their new development "Thorpe
							Hall Gardens", an exclusive development of nineteen spaciously
							designed bungalows boasting high specifications on both the inside
							and out. Following the success of the neighbouring well regarded
							“Lady Nelson Gardens”, these homes are crafted to the highest
							quality. Each bungalow is situated on a generous plot, offering
							substantial driveways, large garages and extensive gardens,
							setting them apart from the typical new build developments. Thorpe
							Hall Gardens is set to become one of Tendring's most desirable new
							neighbourhoods offering a collection of three bedroom detached
							bungalows nestled within the rolling countryside and just a short
							drive from the sandy beaches of Frinton on Sea. This exciting new
							community is expertly designed for contemporary living, providing
							the perfect blend of modern comfort with historical charm.
						</p>
					</div>
					<div className="row mt20">
						<div className="col-lg-12">
							<div className="listing-style1 style12">
								<div className="list-thumb">
									<img
										className="w-100"
										src="/images/newhomes/thorpeSitePlanColourCode.jpg"
										alt="Thorpe Hall Gardens"
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="row mt20">
						<div className="col-lg-7">
							<div className="listing-style1 style12">
								<div className="list-thumb">
									<img
										className="w-100"
										src="/images/newhomes/TheBuckingham.png"
										alt=""
									/>
								</div>
								<div className="list-content">
									<h4 className="list-title">The Buckingham</h4>
									<p className="list-text">3 BEDROOM BUNGALOW</p>
									<div className="list-meta d-flex align-items-center">
										<a href="">
											<span className="flaticon-bed"></span>3 bed
										</a>
										<a href="">
											<span className="flaticon-shower"></span>2 bath
										</a>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-5">
							<img
								className="w-100"
								src="/images/newhomes/TheBuckinghamfp.jpg"
								alt=""
							/>
						</div>
					</div>
					<div className="row mt20">
						<div className="col-lg-5">
							<img
								className="w-100"
								src="/images/newhomes/theChatsworthfp.jpg"
								alt=""
							/>
						</div>
						<div className="col-lg-7">
							<div className="listing-style1 style12">
								<div className="list-thumb">
									<img
										className="w-100"
										src="/images/newhomes/theChatsworth.png"
										alt=""
									/>
								</div>
								<div className="list-content">
									<h4 className="list-title">The Chatsworth</h4>
									<p className="list-text">3 BEDROOM BUNGALOW</p>
									<div className="list-meta d-flex align-items-center">
										<a href="">
											<span className="flaticon-bed"></span>3 bed
										</a>
										<a href="">
											<span className="flaticon-shower"></span>2 bath
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row mt20">
						<div className="col-lg-7">
							<div className="listing-style1 style12">
								<div className="list-thumb">
									<img
										className="w-100"
										src="/images/newhomes/theKensington.png"
										alt="The Kensington"
									/>
								</div>
								<div className="list-content">
									<h4 className="list-title">The Kensington</h4>
									<p className="list-text">4 BEDROOM BUNGALOW</p>
									<div className="list-meta d-flex align-items-center">
										<a href="">
											<span className="flaticon-bed"></span>4 bed
										</a>
										<a href="">
											<span className="flaticon-shower"></span>2 bath
										</a>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-5">
							<img
								className="w-100"
								src="/images/newhomes/theKensingtonfp.jpg"
								alt=""
							/>
						</div>
					</div>
					<div className="row mt20">
						<div className="col-lg-5">
							<img
								className="w-100"
								src="/images/newhomes/therichmondfp.jpg"
								alt="The Richmond"
							/>
						</div>
						<div className="col-lg-7">
							<div className="listing-style1 style12">
								<div className="list-thumb">
									<img
										className="w-100"
										src="/images/newhomes/TheRichmond.png"
										alt="The Richmond"
									/>
								</div>
								<div className="list-content">
									<h4 className="list-title">The Richmond</h4>
									<p className="list-text">3 BEDROOM BUNGALOW</p>
									<div className="list-meta d-flex align-items-center">
										<a href="">
											<span className="flaticon-bed"></span>3 bed
										</a>
										<a href="">
											<span className="flaticon-shower"></span>2 bath
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>

					<hr className="orange mt40 mb40" />
					<h2>Lady Nelson Gardens - LAST FEW REMAINING</h2>
					<h3>THORPE-LE-SOKEN</h3>
					<h4>Show home now open Saturday, Sunday & Bank Holidays 10am-3pm.</h4>
					<div className="row wow fadeInUp">
						<div className="row">
							<div className="col-lg-6 mt20">
								<img
									className="w-100"
									src="/images/newhomes/ladynelson.jpg"
									alt="Lady Nelson"
								/>
							</div>
							<div className="col-lg-6 mt20">
								<iframe
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
									referrerPolicy="strict-origin-when-cross-origin"
									title="Lady Nelson Gardens Thorpe-le-soken"
									width="640"
									height="360"
									src="https://www.youtube-nocookie.com/embed/CMy0v8NuNyU?controls=0&amp;rel=0&amp;playsinline=1&amp;modestbranding=0&amp;autoplay=1&amp;enablejsapi=1&amp;origin=https%3A%2F%2Fblake-thickbroom.co.uk&amp;widgetid=1"
									id="widget2"></iframe>
							</div>
						</div>
						<h4 className="mt20">Welcome to Lady&nbsp;Nelson</h4>
						<p>
							Gardens Lady Nelson Gardens is a graceful collection of
							traditionally styled new homes, relishing a high specification
							internally and externally, consequentially creating homes that
							stand the test of time.
						</p>
						<p>
							Each plot is extremely generous, giving substantial driveways and
							extensive gardens being unique from the average house builder.
						</p>
						<p>
							The development is situated alongside English Heritage Listed
							Gardens of the former Thorpe Hall. Thorpe Hall was demolished and
							rebuilt as the Lifehouse Spa &amp; Hotel. The gardens of Thorpe
							Hall are a true treasure and were formerly used as a Lady Nelson
							convalescent home for employees of English Electric. A magical mix
							of lakes, pools, rock gardens, wild grass areas, rose gardens,
							sunken gardens, a summer house and lots of places to relax.
						</p>
						<p>
							Lady Nelson Gardens is one of Tendring&rsquo;s most desired new
							neighbourhoods offering a superb collection of 3, 4 &amp; 5
							bedroom homes over 2 storeys. Being situated within an established
							oak tree lined development, nestled within the rolling
							countryside, this exciting new community is expertly designed for
							contemporary living.
						</p>
						<div className="row mt20 wow fadeInUp">
							<div className="col-lg-6 mt20">
								<h4 className="mt20">Village&nbsp;Living</h4>
								<p>
									Thorpe-le-Soken dates from Saxon times and has a number of
									historic houses and buildings providing the village with a
									character distinct from Frinton, Clacton and Walton which
									developed as seaside resorts. Much of the village has changed
									relatively little over the last hundred years and the main
									street is particularly familiar.
								</p>
								<p>
									Thorpe-le-Soken has a pre-school, Rolph C of E Primary, and
									Tendring College campus for 11-14s, with the 14-18s campus in
									Frinton. The Frinton and Walton Pool, and Clacton Leisure
									Centre offer swimming, racquet sports, and school holiday
									programmes. For adults Thorpe-le-Soken&rsquo;s Country Fit Gym
									hosts activities from Indoor Cycling to FitSteps&reg; Latin
									and Ballroom fused with fitness moves.
								</p>
								<p>
									Neighbouring Lifehouse Hotel &amp; Spa &ndash; Enter a world
									of complete serenity in an adults only oasis &mdash; the
									perfect place for some well-deserved pampering and relaxation.
									Lifehouse Spa &amp; Hotel is a contemporary award-winning spa
									nestled within 12 acres of peaceful English Heritage Listed
									Gardens, offering luxury treatments, and fine dining.
								</p>
								<p>
									Vast dining options along Thorpe-le-Soken high street include
									The Bell Inn which has been awarded two AA Rosettes for
									culinary excellence, and is now officially considered an AA
									four-star establishment. The facility, which serves up
									breakfasts, lunches, snacks, and evening meals, has also just
									been named as Trip Advisor&rsquo;s Travellers&rsquo; Choice
									Best of the Best winner with magnificent open fireplace and
									south-facing terrace. Alternatively enjoy a cocktail at
									Harry&rsquo;s Bar and Restaurant or a wholesome meal at the
									highly regarded Rose and Crown restaurant. You will find Tesco
									Express and hair salons, pharmacy and local stores in
									Thorpe-le-Soken, with wider shopping, including Morrisons,
									Asda and Sainsburys, in Clacton. Village groups range from
									toddler sessions to choir singing, with Thorpe Sports and
									Social Club being an entertainment and social hub.
								</p>
								<p>
									Explore the area all-year round, with a short scenic walk to
									Thorpe Hall Gardens with its spring snowdrops, summer scents
									and winter wonderland with frozen lake, to the glorious
									seafront gardens, golden sands, and high summer&rsquo;s
									Carnival and Airshow in Clacton. Golfers will find a links
									course at Frinton Golf Club, rated 11th in a Top 100 list and
									at Clacton on-Sea for some of the county&rsquo;s finest
									greens. The coast and birdlife are of global importance
									&ndash; head to Hamford Water Nature Reserve or to Horsey
									Island at low-tide across The Wade from Kirby le-Soken to feel
									away from it all.
								</p>
								<p>
									Yet with Thorpe-le-Soken trains direct to London Liverpool
									Street in 1h 18 minutes, city connectivity is never
									compromised with a 12 minute walk from the development. The
									B1033 and B1414 cross in the village, the A133 links to the
									A120 and A12, Ipswich is 26 miles, and Stansted 50 miles.
								</p>
								<div className="mt10">
									<a
										className="ud-btn btn-thm"
										target="_blank"
										href="https://online.fliphtml5.com/wnizc/ijxs/#p=1">
										Brochure <i className="fal fa-arrow-right-long"></i>
									</a>
								</div>
							</div>
							<div className="col-lg-6 mt20">
								<img
									className="w-100 mt20"
									src="/images/newhomes/lady1.jpg"
									alt="Lady Nelson"
								/>
								<img
									className="w-100 mt20"
									src="/images/newhomes/lady2.jpg"
									alt="Lady Nelson"
								/>
								<img
									className="w-100 mt20"
									src="/images/newhomes/lady3.jpg"
									alt="Lady Nelson"
								/>
							</div>
						</div>

						<div className="row mt20">
							<div className="col-lg-7">
								<div className="listing-style1 style12">
									<div className="list-thumb">
										<img
											className="w-100"
											src="/images/newhomes/thechurchill.jpg"
											alt=""
										/>
									</div>
									<div className="list-content">
										<h4 className="list-title">The Churchill</h4>
										<p className="list-text">PLOTS 20, 21 - 3 BEDROOM HOUSE</p>
										<div className="list-meta d-flex align-items-center">
											<a href="">
												<span className="flaticon-bed"></span>3 bed
											</a>
											<a href="">
												<span className="flaticon-shower"></span>2 bath
											</a>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-5">
								<img
									className="w-100"
									src="/images/newhomes/thechurchillfp.jpg"
									alt=""
								/>
							</div>
						</div>

						<div className="row mt20">
							<div className="col-lg-5">
								<img
									className="w-100"
									src="/images/newhomes/Corinthianfp.jpg"
									alt=""
								/>
							</div>
							<div className="col-lg-7">
								<div className="listing-style1 style12">
									<div className="list-thumb">
										<img
											className="w-100"
											src="/images/newhomes/Corinthian.jpg"
											alt=""
										/>
									</div>
									<div className="list-content">
										<h4 className="list-title">The Corinthian</h4>
										<p className="list-text">PLOTS 9, 25 - 3 BEDROOM</p>
										<div className="list-meta d-flex align-items-center">
											<a href="">
												<span className="flaticon-bed"></span>3 bed
											</a>
											<a href="">
												<span className="flaticon-shower"></span>2 bath
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="row mt20">
							<div className="col-lg-7">
								<div className="listing-style1 style12">
									<div className="list-thumb">
										<img
											className="w-100"
											src="/images/newhomes/theRegency.jpg"
											alt=""
										/>
									</div>
									<div className="list-content">
										<h4 className="list-title">The Regency</h4>
										<p className="list-text">
											PLOTS 3, 4, 6, 13, 26, 27, 30 - 4 BEDROOM HOUSE
										</p>
										<div className="list-meta d-flex align-items-center">
											<a href="">
												<span className="flaticon-bed"></span>4 bed
											</a>
											<a href="">
												<span className="flaticon-shower"></span>2 bath
											</a>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-5">
								<img
									className="w-100"
									src="/images/newhomes/theRegencyfp.jpg"
									alt=""
								/>
							</div>
						</div>

						<div className="row mt20">
							<div className="col-lg-5">
								<img
									className="w-100"
									src="/images/newhomes/Gablesfp.jpg"
									alt=""
								/>
							</div>
							<div className="col-lg-7">
								<div className="listing-style1 style12">
									<div className="list-thumb">
										<img
											className="w-100"
											src="/images/newhomes/Gables.jpg"
											alt=""
										/>
									</div>
									<div className="list-content">
										<h4 className="list-title">The Gables</h4>
										<p className="list-text">
											PLOTS 5, 8, 254, 28 - 4 BEDROOM HOUSE
										</p>
										<div className="list-meta d-flex align-items-center">
											<a href="">
												<span className="flaticon-bed"></span>4 bed
											</a>
											<a href="">
												<span className="flaticon-shower"></span>3 bath
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="row mt20">
							<div className="col-lg-7">
								<div className="listing-style1 style12">
									<div className="list-thumb">
										<img
											className="w-100"
											src="/images/newhomes/Manor.jpg"
											alt=""
										/>
									</div>
									<div className="list-content">
										<h4 className="list-title">The Manor</h4>
										<p className="list-text">
											PLOTS 7, 10, 19 - 4 BEDROOM HOUSE
										</p>
										<div className="list-meta d-flex align-items-center">
											<a href="">
												<span className="flaticon-bed"></span>4 bed
											</a>
											<a href="">
												<span className="flaticon-shower"></span>2 bath
											</a>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-5">
								<img
									className="w-100"
									src="/images/newhomes/Manorfp.jpg"
									alt=""
								/>
							</div>
						</div>

						<div className="row mt20">
							<div className="col-lg-5">
								<img
									className="w-100"
									src="/images/newhomes/Belvederefp.jpg"
									alt=""
								/>
							</div>
							<div className="col-lg-7">
								<div className="listing-style1 style12">
									<div className="list-thumb">
										<img
											className="w-100"
											src="/images/newhomes/Belvedere.jpg"
											alt=""
										/>
									</div>
									<div className="list-content">
										<h4 className="list-title">The Belvedere</h4>
										<p className="list-text">
											PLOTS 2, 15, 22, 29 - 4 BEDROOM HOUSE
										</p>
										<div className="list-meta d-flex align-items-center">
											<a href="">
												<span className="flaticon-bed"></span>4 bed
											</a>
											<a href="">
												<span className="flaticon-shower"></span>3 bath
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="row mt20">
							<div className="col-lg-7">
								<div className="listing-style1 style12">
									<div className="list-thumb">
										<img
											className="w-100"
											src="/images/newhomes/Georgian.jpg"
											alt=""
										/>
									</div>
									<div className="list-content">
										<h4 className="list-title">The Georgian</h4>
										<p className="list-text">PLOTS 14, 23 - 5 BEDROOM HOUSE</p>
										<div className="list-meta d-flex align-items-center">
											<a href="">
												<span className="flaticon-bed"></span>5 bed
											</a>
											<a href="">
												<span className="flaticon-shower"></span>3 bath
											</a>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-5">
								<img
									className="w-100"
									src="/images/newhomes/Georgianfp.jpg"
									alt=""
								/>
							</div>
						</div>

						<div className="row mt20">
							<div className="col-lg-5">
								<img
									className="w-100"
									src="/images/newhomes/Grandeurfp.jpg"
									alt=""
								/>
							</div>
							<div className="col-lg-7">
								<div className="listing-style1 style12">
									<div className="list-thumb">
										<img
											className="w-100"
											src="/images/newhomes/Grandeur.jpg"
											alt=""
										/>
									</div>
									<div className="list-content">
										<h4 className="list-title">The Grandeur</h4>
										<p className="list-text">PLOT 18 - 5 BEDROOM HOME</p>
										<div className="list-meta d-flex align-items-center">
											<a href="">
												<span className="flaticon-bed"></span>5 bed
											</a>
											<a href="">
												<span className="flaticon-shower"></span>4 bath
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="row mt20">
							<div className="col-lg-7">
								<div className="listing-style1 style12">
									<div className="list-thumb">
										<img
											className="w-100"
											src="/images/newhomes/Statesman.jpg"
											alt=""
										/>
									</div>
									<div className="list-content">
										<h4 className="list-title">The Statesman</h4>
										<p className="list-text">
											PLOTS 1, 11, 12, 16, 17 - 5 BEDROOM HOUSEE
										</p>
										<div className="list-meta d-flex align-items-center">
											<a href="">
												<span className="flaticon-bed"></span>5 bed
											</a>
											<a href="">
												<span className="flaticon-shower"></span>3 bath
											</a>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-5">
								<img
									className="w-100"
									src="/images/newhomes/Statesmanfp.jpg"
									alt=""
								/>
							</div>
						</div>
					</div>

					<hr className="orange mt40 mb40" />
					<div className="row wow fadeInUp">
						<div className="col-lg-6 mt20">
							<h2>Foots Farm Development</h2>
							<h4>CLACTON ON SEA</h4>
							<div>
								<p>
									Stunning new private development of three bedroom detached
									bungalows situated in this recessed position within the
									grounds of the renowned Foots Farmhouse. This development
									offers traditionally built designed bungalows offering
									spacious and well appointed accommodation and will be finished
									with a high standard of workmanship throughout giving comfort
									to any purchaser looking to buy their dream home. The
									development is situated on the Northern outskirts of
									Clacton&rsquo;s town centre and is within a short distance of
									local supermarkets, bus route to Clacton&rsquo;s town centre
									and Clacton&rsquo;s Shopping Village which provides a range of
									excellent shopping facilities. The development offers
									excellent main road access to the popular villages of Little
									Clacton and Thorpe le Soken and access to the A120 providing
									direct routes to both Clacton and Sea and the historic City of
									Colchester.
									<br />
								</p>
								<ul>
									<li>
										Five x Three Bedroom Detached Bungalows for this phase
									</li>
									<li>Ensuite Shower Room</li>
									<li>Luxury Fitted Bathroom</li>
									<li>Gas Heating via Radiators</li>
									<li>Lounge with French Style doors</li>
									<li>Luxury Fitted Kitchen Diner with Built in Appliances</li>
									<li>Ten Year Builder&rsquo;s Warranty</li>
									<li>Floor Coverings and Carpets Included</li>
									<li>Fully Double Glazed</li>
									<li>Block Paved Driveway</li>
									<li>Lawned Front and Rear Gardens</li>
									<li>Oak Doors to all Plots</li>
									<li>Outside Security Lighting and Tap</li>
									<li>Private Development</li>
									<li>Walk in Wardrobes to Plots 1 &amp; 4</li>
									<li>South Facing Garden to all Plots</li>
									<li>Sole Agents</li>
								</ul>
							</div>
						</div>
						<div className="col-lg-6 mt20">
							<img
								className="w-100 mt20"
								src="/images/newhomes/FootsFarm1.jpg"
								alt="Foots Farm"
							/>
							<img
								className="w-100 mt20"
								src="/images/newhomes/FootsFarm2.jpg"
								alt="Foots Farm"
							/>
						</div>
					</div>
					<div className="row wow fadeInUp">
						<div className="col-lg-6 mt20">
							<img
								className="w-100 mt20"
								src="/images/newhomes/FootsFarm3.jpg"
								alt="Foots Farm"
							/>
						</div>
						<div className="col-lg-6 mt20">
							<img
								className="w-100 mt20"
								src="/images/newhomes/FootsFarm4.jpg"
								alt="Foots Farm"
							/>
						</div>
					</div>
					<div className="row wow fadeInUp">
						<div className="col-lg-6 mt20">
							<img
								className="w-100 mt20"
								src="/images/newhomes/FootsFarm5.jpg"
								alt="Foots Farm"
							/>
						</div>
						<div className="col-lg-6 mt20">
							<img
								className="w-100 mt20"
								src="/images/newhomes/FootsFarm6.jpg"
								alt="Foots Farm"
							/>
						</div>
					</div>

					<hr className="orange mt40 mb40" />
					<div className="row wow fadeInUp">
						<div className="col-lg-6 mt20">
							<h2>St Johns Oak</h2>
							<h3>CLACTON ON SEA, CO16 8GU</h3>
							<h2>
								<strong>St Johns Oak&nbsp; Prices from &pound;385,000</strong>
							</h2>
							<p>
								&ldquo;St Johns Oak &rdquo; is a stunning new development of two
								and three bedroom detached bungalows situated within a recessed
								position on the north-western outskirts of Clacton&rsquo;s town
								centre. St Johns Oak will offer a variety of traditionally built
								designed bungalows with spacious and well appointment
								accommodation which will be finished to high specification
								throughout and be offered for sale with a 10 year builders
								warranty giving peace of mind to any prospective purchaser that
								would want to buy their dream home. The development is
								conveniently located within walking distance of local garden
								centre and shopping facilities at Bockings Elm and is within
								walking distance of bus route to Clacton&rsquo;s town centre
								offering a range of excellent shopping facilities, restaurants,
								mainline railway station and stunning sea front beaches and
								gardens. Early reservations are considered on phase 1 which is
								due for completion in March/April 2023.
							</p>
						</div>
						<div className="col-lg-6 mt20">
							<iframe
								className="elementor-video"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
								referrerPolicy="strict-origin-when-cross-origin"
								title="St Johns Oak Clacton"
								width="640"
								height="360"
								src="https://www.youtube-nocookie.com/embed/6rsS3RtcqnM?controls=0&amp;rel=0&amp;playsinline=1&amp;modestbranding=0&amp;autoplay=1&amp;enablejsapi=1&amp;origin=https%3A%2F%2Fblake-thickbroom.co.uk&amp;widgetid=3"
								id="widget4"></iframe>
						</div>
					</div>
					<div className="row wow fadeInUp">
						<div className="col-lg-6 mt20">
							<img
								className="w-100 mt20"
								src="/images/newhomes/StJohnsoak1.jpg"
								alt=">St Johns Oak"
							/>
						</div>
						<div className="col-lg-6 mt20">
							<img
								className="w-100 mt20"
								src="/images/newhomes/StJohnsoak2.jpg"
								alt="St Johns Oak"
							/>
							<img
								className="w-100 mt20"
								src="/images/newhomes/StJohnsoak3.jpg"
								alt="St Johns Oak"
							/>
						</div>
					</div>
					<div className="row wow fadeInUp">
						<div className="col-lg-6 mt20">
							<img
								className="w-100 mt20"
								src="/images/newhomes/StJohnsoak4.jpg"
								alt="St Johns Oak"
							/>
						</div>
						<div className="col-lg-6 mt20"></div>
					</div>

					<hr className="orange mt40 mb40" />
					<div className="row wow fadeInUp">
						<div className="col-lg-6 mt20">
							<img
								className="w-100 mt20"
								src="/images/newhomes/Weeley1.jpg"
								alt="Weeley Road"
							/>
							<img
								className="w-100 mt20"
								src="/images/newhomes/Weeley2.jpg"
								alt="Weeley Road"
							/>
						</div>
						<div className="col-lg-6 mt20">
							<h2>Weeley Road - All Plots Sold !</h2>
							<h3>LITTLE CLACTON</h3>
							<h3>
								<strong>&pound;575,000</strong>
							</h3>
							<p>
								Stunning brand new three bedroom detached bungalows forming part
								of a small development in this semi rural location with farmland
								views from this south westerly facing garden on the outskirts of
								the popular village of Little Clacton. These beautiful bungalows
								&nbsp;will offer substantial living space of approximately
								1400sq ft (excluding roof space and garage) and will be uniquely
								constructed with first floor attic roof trusses and the ability
								to install stair flight to offer additional first floor living
								space in the future if desired. The bungalows will be built to a
								high specification throughout and will be completed with an
								insurance backed 10 years builders warranty to offer peace of
								mind of any prospective purchaser for the quality of build. As
								the vendors chosen sole agent early reservations are strongly
								recommended to avoid disappointment.
							</p>
							<img
								className="w-100 mt20"
								src="/images/newhomes/Weeley3.jpg"
								alt="Weeley Road"
							/>
						</div>
					</div>

					<hr className="orange mt40 mb40" />
					<div className="row wow fadeInUp">
						<div className="col-lg-6 mt20">
							<h2>Bluehouse Gardens - All Plots Sold ! </h2>
							<h3>CLACTON ON SEA, CO16 8GU</h3>
							<p>
								A Stunning new private development of two and three bedroom
								detached bungalow situated in a recessed position with a Gardens
								Address on the western outskirts of Clacton&rsquo;s town centre.
								Bluehouse Gardens will offer a variety of traditionally built
								designed bungalows offering spacious and well appointed
								accommodation on this thoughtfully planned development. Willow
								Park Group are the developers, who will ensure that each
								bungalow will be finished to a high specification with the
								comfort of a 10 year builders warranty, giving peace of mind to
								a purchaser looking to buy their dream retirement home. The
								development is situated on the western outskirts approximately
								two miles from Clacton&rsquo;s town centre, which offers a range
								of excellent shopping facilities, restaurants, mainline railway
								station and stunning regenerated seafront beaches and gardens.
								Wick Lodge Public House and carvery restaurant are within
								walking distance and the development is conveniently located
								with a short walk to local bus routes, town centre and main road
								access to both Colchester and London. Highlighted features of
								the development are as follows:
							</p>
							<p>&nbsp;</p>
							<ul>
								<li>
									Phase III consists of nine three bedroom link-detached
									bungalows
								</li>
								<li>En Suites to all designs</li>
								<li>Luxury Fitted Bathroom</li>
								<li>Gas Heating via Radiators</li>
								<li>Spacious Lounge</li>
								<li>Luxury Fitted Kitchen/Diners with French Doors</li>
								<li>10 Year Builders Warranty</li>
								<li>&nbsp;Utility Rooms</li>
								<li>Conservatories to plots 1,2,3,4,9 &amp;10.</li>
								<li>Lawned front and rear gardens</li>
								<li>Choice of carpets/floor coverings</li>
								<li>Fully Double Glazed</li>
								<li>23&rsquo; Garages with power and light connected</li>
								<li>Outside Security Lighting &amp; Tap</li>
								<li>Private Development</li>
							</ul>
							<p>&nbsp;</p>
							<p>
								<strong>Prices from &pound;350,000</strong>
							</p>
						</div>
						<div className="col-lg-6 mt20">
							<img
								className="w-100 mt20"
								src="/images/newhomes/bluehouse1.jpg"
								alt="Bluehouse Gardens"
							/>
							<img
								className="w-100 mt20"
								src="/images/newhomes/bluehouse2.jpg"
								alt="Bluehouse Gardens"
							/>
							<img
								className="w-100 mt20"
								src="/images/newhomes/bluehouse3.jpg"
								alt="Bluehouse Gardens"
							/>
						</div>
					</div>

					<hr className="orange mt40 mb40" />
					<div className="row wow fadeInUp">
						<div className="col-lg-6 mt20">
							<img
								className="w-100 mt20"
								src="/images/newhomes/Orchard1.jpg"
								alt="Orchard Crescent"
							/>
							<img
								className="w-100 mt20"
								src="/images/newhomes/Orchard2.jpg"
								alt="Orchard Crescent"
							/>
							<img
								className="w-100 mt20"
								src="/images/newhomes/Orchard3.jpg"
								alt="Orchard Crescent"
							/>
						</div>
						<div className="col-lg-6 mt20">
							<h2>Orchard Crescent - All Plots Sold !</h2>
							<h3>KIRBY CROSS, FRINTON ON SEA</h3>
							<p>
								A stunning new private development of three bedroom detached
								bungalows and three bedroom semi detached houses in a quiet
								recessed position adjacent to the highly regarded Orchard
								Gardens development in this sought after village. &ldquo;Orchard
								Crescent&rdquo; will offer a variety of traditionally built
								design homes and EMP Promotions Limited are a well accomplished
								development company and will ensure each design home will be
								built to a high specification with every home being covered by a
								ten year insurance backed builder&rsquo;s warranty, giving peace
								of mind to every purchaser whether buying for retirement or
								family home. The development is situated within the sought after
								and unspoilt village of Kirby Cross and is conveniently located
								within walking distance of local village stores, Kirby Cross
								Primary School, mainline railway station and bus routes to both
								Clacton on Sea and Frinton on Sea town centres, both offering
								panoramic coastline views.
							</p>
							<ul>
								<li>Four (three bedrooms) Semi Detached Houses</li>
								<li>Four (three bedroom) Detached Bungalows</li>
								<li>Private Recessed Development</li>
								<li>En-suite Shower to Bungalows</li>
								<li>Gas Heating via Radiators</li>
								<li>Fully Double Glazed</li>
								<li>Ten Year Insurance Backed Builder&rsquo;s Warranty</li>
								<li>
									Luxury Fitted Kitchen with Integrated Appliances Including
									Oven, Hob Unit, Extractor Hood, Fridge &amp; Freezer, Built in
									Microwave
								</li>
								<li>Lounge with French Style Doors</li>
								<li>Luxury Fitted Bathrooms</li>
								<li>
									Electric Roller Shutter Doors to Garages to all Bungalows
								</li>
								<li>Two Parking Spaces to each House</li>
								<li>Lawned Front and Rear Gardens</li>
								<li>Choice of Carpets / Floor Coverings</li>
								<li>External Lighting and Water Tap</li>
							</ul>
							<p>
								<strong>
									Prices from &pound;285,000 for houses &amp; From
									&pound;395,000 for bungalows
								</strong>
							</p>
						</div>
					</div>

					<hr className="orange mt40 mb40" />
					<div className="row wow fadeInUp">
						<div className="col-lg-6 mt20">
							<h2>Edward Close - All Plots Sold !</h2>
							<h3>LITTLE CLACTON, CO16 9PR</h3>
							<p>
								Situated in this semi-rural location and tucked away in this
								recessed position within a quiet cul de sac are these
								&ldquo;simply stunning&rdquo; three bedroom detached bungalows.
								Built with traditional elevations with a high standard of
								workmanship throughout, these homes offer substantial
								accommodation with open plan living at the heart of the design.
								These homes are completed with a ten-year build warranty, giving
								peace of mind to a purchaser looking for their dream retirement
								home and come with a Southerly facing rear garden with
								uninterrupted farmland views.
							</p>
							<p>
								<strong>Location</strong>
							</p>
							<p>
								The development is situated on the outskirts of the popular
								village of Little Clacton with main road access to the village
								centre and Thorpe le Soken village which offers an excellent
								array of shopping facilities and mainline railway station to
								London Liverpool Street. The A120 is approximately a three-mile
								distance through the village of Little Clacton and Weeley, which
								gives direct routes to both Clacton on Sea and
								Colchester&rsquo;s town centre.
							</p>
							<p>For satellite directions, please use postcode CO16 9PR.</p>
							<p>Edward Close specification.</p>
							<ul>
								<li>
									Three x three bedroom detached bungalows (Plot 3 with parking,
									no garage)
								</li>
								<li>
									En-suite shower with separate bathroom with fully tiled walls
								</li>
								<li>
									Gas heating via radiators with Anthracite upright radiators to
									main living areas
								</li>
								<li>
									Luxury fitted kitchens with White granite Bloomstone Snowden
									worktops, inset sink unit and numerous integrated appliances,
									including hob unit, extractor hood, eye level double oven,
									microwave oven, dishwasher, fridge and freezer
								</li>
								<li>
									Lounge with bi-folding doors and wall hung flueless feature
									gas fire
								</li>
								<li>Genoa oak doors throughout with black nickel handles.</li>
								<li>Alarm security system</li>
								<li>Oak flooring to living areas and hallway</li>
								<li>Ten year buildzone insurance backed warranty</li>
								<li>Fully double glazed, high specification throughout</li>
								<li>
									23&prime; garages with power and light connected and electric
									operated remote control entrance doors (Plot 3 has two parking
									spaces instead of garage)
								</li>
								<li>Carpets and floor coverings included</li>
								<li>
									Lawned front and rear gardens with patio area outside tap and
									outside power point, Uninterrupted farmland views.
								</li>
							</ul>
							<p>
								<strong>Prices from &pound;400,000</strong>
							</p>
						</div>
						<div className="col-lg-6 mt20">
							<img
								className="w-100 mt20"
								src="/images/newhomes/Edward1.jpg"
								alt="Edward Close"
							/>
							<img
								className="w-100 mt20"
								src="/images/newhomes/Edward2.jpg"
								alt="Edward Close"
							/>
						</div>
					</div>

					<hr className="orange mt40 mb40" />
					<div className="row wow fadeInUp">
						<div className="col-lg-6 mt20">
							<img
								className="w-100 mt20"
								src="/images/newhomes/Oakview1.jpg"
								alt="Oakview"
							/>
							<img
								className="w-100 mt20"
								src="/images/newhomes/Oakview2.jpg"
								alt="Oakview"
							/>
							<img
								className="w-100 mt20"
								src="/images/newhomes/Oakview3.jpg"
								alt="Oakview"
							/>
						</div>
						<div className="col-lg-6 mt20">
							<h2>Oakview Crescent - All Plots Sold !</h2>
							<h3>CLACTON ON SEA, CO16 8HU</h3>
							<p>
								&lsquo;Oakview Crescent&rsquo; is a stunning collection of
								individually designed homes in a semi-rural location on the
								outskirts of the popular seaside town of Clacton on Sea. Burfoot
								Homes Ltd, a reputable and established local firm of builders
								have designed and developed these homes to be finished to a high
								specification throughout with a 10 year HHBC certificate, tailor
								made for the needs of a prospective purchaser. The development
								occupies a pleasant recess position on the St Johns Road and is
								in easy access to all amenities including local schools,
								shopping facilities, bus routes to Clacton on Sea with its
								regenerated golden seafront beaches and landscaped seafront
								gardens and thriving town centre.
							</p>
							<p>&nbsp;</p>
							<ul>
								<li>
									Phase two consists of 22 two and three bedroomed semi and
									detached bungalows
								</li>
								<li>En suite showers</li>
								<li>Luxury bathrooms</li>
								<li>Lounge with bi folding doors</li>
								<li>Choice of carpets, tiles and kitchens</li>
								<li>Alarm systems</li>
								<li>Gas heating via radiators</li>
								<li>Double glazed throughout</li>
								<li>Luxury fitted kitchens with integrated appliances</li>
								<li>Block paved driveway</li>
								<li>Private broadband point installed</li>
								<li>10 year HHBC certificate</li>
								<li>Lawned front and rear gardens</li>
								<li>Sole agents</li>
							</ul>
							<div>
								<strong>Prices from &pound;385,000</strong>
							</div>
						</div>
						<div className="row wow fadeInUp">
							<div className="col-lg-6 mt20">
								<div className="listing-style1 style12">
									<div className="list-thumb">
										<img
											className="w-100"
											src="/images/newhomes/Oakview-greatholland.jpg"
											alt="Great Holland"
										/>
									</div>
									<div className="list-content">
										<h4 className="list-title">Great Holland - SOLD ! </h4>
										<p className="list-text">MAIN ROAD, GREAT HOLLAND</p>
										<p>
											Situated in this semi-rural location in the heart of this
											unspoilt village are these two stunning new detached
											chalet style houses, built to a high specification
											throughout with modern elevations and offering spacious
											and well planned living accommodation on two floors. These
											homes are located within a short walk of the beautiful
											church hall with open countryside views and have excellent
											main road access to both Frinton on Sea and Clacton on Sea
											town Centres, both offering an array of shopping
											facilities , mainline rail links and Stunning Coastlines.
											Early reservations are considered.
										</p>
										<p>&nbsp;</p>
										<ul>
											<li>THREE BEDROOMS *&nbsp;ENSUITE SHOWER ROOM</li>
											<li>
												FAMILY BATHROOM * GAS HEATING VIA RADIATORS AND
												UNDERFLOOR
											</li>
											<li>
												PROFESSIONAL CONSULTANCY CERTIFICATE FOR CONSTRUCTION
											</li>
											<li>HIGH SPECIFICATION THROUGHOUT</li>
											<li>TRIPLE GLAZED WINDOWS</li>
											<li>16&rsquo;5 x 10&rsquo;8 LOUNGE plus BAY RECESS</li>
											<li>
												17&rsquo;1 x 14&rsquo;1 LUXURY FITTED KITCHEN DINER WITH
												BI-FOLD DOORS AND NUMEROUS INTEGRATED APPLIANCES
												INCLUDING OVEN, HOB UNIT, EXTRACTOR HOOD, DISHWASHER,
												FRIDGE + FREEZER
											</li>
											<li>UTILITY ROOM * GROUND FLOOR CLOAKROOM</li>
											<li>CHOICE OF FLOOR COVERINGS</li>
											<li>
												LAWNED FRONT AND REAR GARDENS WITH RESIN BOUND PATIO
											</li>
											<li>
												PARKING FOR TWO VEHICLES WITH RESIN BOUND DRIVEWAY
											</li>
										</ul>
										<p>&nbsp;</p>
										<p>
											<strong>Prices &pound;395,000</strong>
										</p>
									</div>
								</div>
							</div>
							<div className="col-lg-6 mt20">
								<div className="listing-style1 style12">
									<div className="list-thumb">
										<img
											className="w-100"
											src="/images/newhomes/Oakview-acorns.jpg"
											alt="The Acorns"
										/>
									</div>
									<div className="list-content">
										<h4 className="list-title">The Acorns - SOLD ! </h4>
										<p className="list-text">CHURCH LANE, GREAT HOLLAND</p>
										<p>
											Situated in a semi rural location in the heart of this
											quiet unspoilt village is Plot 2, The Acorns, the first to
											be released of two individually designed stunning new
											homes. Built to a high specification with distinctive
											elevations, these homes seamlessly blend in with the&nbsp;
											picturesque surroundings including stunning views of the
											church tower. Each home offers substantial living
											accommodation (Plot 2 offers 168sq/m plus Garage) with
											exquisite architect designed features including vaulted
											ceilings to main rooms. These homes are designed to suit a
											range of lifestyle needs and comfort for the build quality
											is given by a comprehensive ten year warranty. Situated
											within a conservation area , these homes are located
											within a short walk of the beautiful church hall and a 15
											minute walk through countryside to Frinton on sea . From
											the village there is excellent main road access to both
											Clacton on Sea and Frinton on Sea , both offering an array
											of shopping facilities and stunning coastline.
										</p>
										<p>&nbsp;</p>
										<ul>
											<li>
												18&rsquo;3 x 12&rsquo;6 MASTER BEDROOM * TWO FURTHER
												BEDROOMS * BUILT IN WARDROBES TO ALL BEDROOMS
												*&nbsp;ENSUITE SHOWER ROOM
											</li>
											<li>
												13&rsquo;3 LUXURY BATHROOM * 18&rsquo;8 max&nbsp; LOUNGE
											</li>
											<li>
												7&prime; x 13&rsquo;2 LUXURY FITTED KITCHEN
												WITH&nbsp;CENTRE ISLAND ,QUARTZ WORKTOPS AND HIGH
												QUALITY&nbsp; INTEGRATED APPLIANCES INCLUDING
												&ldquo;NEFF&rdquo; FIVE RING HOB UNIT, EXTRACTOR HOOD,
												BUILT IN DOUBLE OVEN, MICROWAVE OVEN, FRIDGE, FREEZER,
												DISHWASHER, WINE COOLER
											</li>
											<li>
												18&rsquo;8 x 15&rsquo;5 LIVING/DINING ROOM WITH BI-FOLD
												DOORS TO GARDEN * UTILITY ROOM * CLOAKROOM
											</li>
											<li>
												21&rsquo;4 x 10&prime; DETACHED GARAGE WITH REMOTE
												CONTROL UP &amp; OVER DOOR * ACCESS FROM PRIVATE ROAD TO
												DRIVEWAY ENTRANCE WITH&nbsp; SUBSTANTIAL PARKING
											</li>
											<li>TEN YEAR WARRANTY FOR CONSTRUCTION</li>
											<li>
												HIGH SPECIFICATION THROUGHOUT * CARPETS &amp; FLOOR
												COVERINGS INCLUDED
											</li>
											<li>
												WIRING INSTALLED FOR ELECTRIC CAR RECHARGING POINT *
												VAULTED CEILINGS TO MAIN ROOMS WITH REMOTE
												CONTROL&nbsp;VELUX WINDOWS * UNDERFLOOR GAS HEATING
											</li>
										</ul>
										<p>
											<strong>Price &pound;675,000</strong>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>

					<hr className="orange mt40 mb40" />
					<div className="row">
						<h2>Are You Looking to Buy a New Build?</h2>
						<p>
							Buyers can also benefit from our great new homes and land service.
						</p>
						<p>
							Our relationship with developers means that we can keep you firmly
							in the loop about upcoming developments and projects that may be
							perfect for your requirements and the specifications of your new
							build home.
						</p>
						<p>
							This means you won&rsquo;t miss out on your dream home! Get in
							touch with us to find out more today.
						</p>
					</div>
				</div>
			</section>
		</div>
	);
}

export default NewHomes;
