import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Details } from '../Models/detailsModel';
import { Rooms } from '../Models/roomModel';
import { API_BASE_URL, API_KEY } from '../Constants/constants';
import useMetaTags from './metatags';
import { SubmitHandler, useForm } from 'react-hook-form';

interface IFormInput {
	firstname: string;
	surname: string;
	email: string;
	message: string;
}


const PropertyDetails: React.FC = (): JSX.Element => {
	const params = useParams();
	const { id } = params;
	const [message, setMessage] = useState('');
	const [errormessage, seterrorMessage] = useState('');
	const [propertyDetails, setProperties] = useState<Details>();
	const [roomDetails, setRooms] = useState<Rooms[]>([]);
	const [bulletArray, setArrayResult] = useState<string[]>([]);
	useMetaTags(
		`${propertyDetails?.property_Type_Text || ''} ${propertyDetails?.street || ''} ${propertyDetails?.district || ''}
		 ${propertyDetails?.town || ''} ${propertyDetails?.county || ''}`,
		propertyDetails?.short_Description
	);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get<any>(
					`${API_BASE_URL}api/details/${id}`
				);
				setProperties(response.data);
            setMessage(
							`I would like more information regarding this property: ${response.data.property_Name} ${response.data.street}, ${response.data.town}, ${response.data.post_Outcode} ${response.data.post_Incode}`
						);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};

		const fetchRoomData = async () => {
			try {
				const response = await axios.get<any>(
					`${API_BASE_URL}api/rooms/${id}`
				);
				setRooms(response.data.rooms);
			} catch (error) {
				console.error('Error fetching room data:', error);
			}
		};

			fetchData();
			fetchRoomData();
		}, [id]);

		useEffect(() => {
			if (propertyDetails) {
				const arrayResult: string[] =
					propertyDetails.bullet_Points?.split('<br />') ?? [];
				setArrayResult(arrayResult);
			}
		}, [propertyDetails]);

		useEffect(() => {
				const referrerLink = document.getElementById(
					'referrerLink'
				) as HTMLAnchorElement;
				if (referrerLink) {
					referrerLink.href = document.referrer || '';
				}
			}, []);

		const {
				register,
				handleSubmit,
				formState: { errors },
			} = useForm<IFormInput>();			

		const onSubmit: SubmitHandler<IFormInput> = async (data, e) => {
				e?.preventDefault(); // Prevent the default form submission

				try {
					await axios.post(`${API_BASE_URL}api/email/send`, data, {
						headers: {
							'x-api-key': API_KEY,
						},
					});

					// Update message and clear form fields
					seterrorMessage('Email sent successfully.');
					e?.target.reset(); // Reset the form fields
					setMessage('');
				} catch (error) {
					console.error('Error sending email:', error);
					seterrorMessage('Failed to send email.');
				}
			};

			

	return (
		<div id="mobile" className="body_content mt50">
			<section className="pt60 pb0 bgc-white">
				<div className="container">
					<div
						className="row wow fadeInUp"
						data-wow-delay="100ms">
						<div className="col-lg-8">
							<div className="single-property-content mb30-md">
								{propertyDetails?.priceLine && (
									<div>
										<h2
											className="sp-lg-title"
											dangerouslySetInnerHTML={{
												__html: propertyDetails.priceLine,
											}}
										/>
									</div>
								)}
								<div className="pd-meta mb15 d-md-flex align-items-center">
									{propertyDetails?.property_Type_Text && (
										<div
											className="text fz15 mb-0 pr10 bdrrn-sm"
											dangerouslySetInnerHTML={{
												__html: propertyDetails.property_Type_Text,
											}}
										/>
									)}
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="single-property-content">
								<div className="property-action text-lg-end">
									<div className="d-flex mb20 mb10-md align-items-center justify-content-lg-end">
										<a
											id="referrerLink"
											className="ud-btn btn-thm"
											href="page-contact.html">
											Back to Search Results
											<i className="fal fa-arrow-left-long"></i>
										</a>
									</div>
									<div className="text fz15 mb-0 pr10 bdrrn-sm">
										{propertyDetails?.address && (
											<div
												className="text"
												dangerouslySetInnerHTML={{
													__html: propertyDetails.address,
												}}
											/>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						className="row mt30 wow fadeInUp"
						data-wow-delay="300ms">
						<div className="col-sm-9">
							<div className="sp-img-content at-sp-v2 mb15-md">
								<a
									className="popup-img preview-img-1 sp-img image-link"
									href={propertyDetails?.picture_URL_1}>
									<img
										className="w-100"
										src={propertyDetails?.picture_URL_1}
										alt={propertyDetails?.property_Type}
									/>
								</a>
							</div>
						</div>
						<div className="col-sm-3">
							<div className="row">
								<div className="col-sm-12 ps-lg-0">
									<div className="sp-img-content">
										<a
											className="popup-img preview-img-2 sp-img mb10 image-link"
											href={propertyDetails?.picture_URL_2}>
											<img
												className="w-100"
												src={propertyDetails?.picture_URL_2}
												alt={propertyDetails?.property_Type}
											/>
										</a>
									</div>
								</div>
								<div className="col-sm-12 ps-lg-0">
									<div className="sp-img-content">
										<a
											className="popup-img preview-img-3 sp-img mb10 image-link"
											href={propertyDetails?.picture_URL_3}>
											<img
												className="w-100"
												src={propertyDetails?.picture_URL_3}
												alt={propertyDetails?.property_Type}
											/>
										</a>
									</div>
								</div>
								<div className="col-sm-12 ps-lg-0">
									<div className="sp-img-content at-sp-v2">
										<a
											className="popup-img preview-img-4 sp-img image-link"
											href={propertyDetails?.picture_URL_4}>
											<img
												className="w-100"
												src={propertyDetails?.picture_URL_4}
												alt={propertyDetails?.property_Type}
											/>
										</a>
										<a
											href={propertyDetails?.picture_URL_1}
											className="all-tag popup-img image-link">
											See All Photos
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row mt30">
						<div className="col-sm-6 col-md-4 col-xl-2">
							<div className="overview-element mb25 d-flex align-items-center">
								<span className="icon flaticon-bed"></span>
								<div className="ml15">
									<h6 className="mb-0">Bedroom</h6>
									<p className="text mb-0 fz15">
										{propertyDetails?.bedroom_Qty}
									</p>
								</div>
							</div>
						</div>
						<div className="col-sm-6 col-md-4 col-xl-2">
							<div className="overview-element mb25 d-flex align-items-center">
								<span className="icon flaticon-home-1"></span>
								<div className="ml15">
									<h6 className="mb-0">Reception</h6>
									<p className="text mb-0 fz15">
										{propertyDetails?.reception_Qty}
									</p>
								</div>
							</div>
						</div>
						<div className="col-sm-6 col-md-4 col-xl-2">
							<div className="overview-element mb25 d-flex align-items-center">
								<span className="icon flaticon-shower"></span>
								<div className="ml15">
									<h6 className="mb-0">Bath</h6>
									<p className="text mb-0 fz15">
										{propertyDetails?.bathroom_Qty}
									</p>
								</div>
							</div>
						</div>
						<div className="col-sm-6 col-md-4 col-xl-2">
							<div className="overview-element mb25 d-flex align-items-center">
								<span className="icon flaticon-event"></span>
								<div className="ml15">
									<h6 className="mb-0">Age</h6>
									<p className="text mb-0 fz15">{propertyDetails?.age}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="pt60 pb90 bgc-f7">
				<div className="container">
					<div
						className="row wrap wow fadeInUp"
						data-wow-delay="500ms">
						<div className="col-lg-8">
							<div className="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30 overflow-hidden position-relative">
								<h4 className="title fz17 mb10">Property Details</h4>
								<div className="text mb10">
									<div className="col-sm-12 col-md-12">
										{propertyDetails?.detailed_Description && (
											<div>
												<p
													className="text"
													dangerouslySetInnerHTML={{
														__html: propertyDetails.detailed_Description,
													}}
												/>
											</div>
										)}
									</div>
								</div>
							</div>
							{propertyDetails?.agent_Note && (
								<div className="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30 overflow-hidden position-relative">
									<h4 className="title fz17 mb10">Agent Notes</h4>
									<div className="row">
										<div className="col-sm-12 col-md-12">
											{propertyDetails?.agent_Note && (
												<div>
													<p
														className="text"
														dangerouslySetInnerHTML={{
															__html: propertyDetails.agent_Note,
														}}
													/>
												</div>
											)}
										</div>
									</div>
								</div>
							)}

							{bulletArray && bulletArray.length > 0 && (
								<div className="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30 overflow-hidden position-relative">
									<h4 className="title fz17 mb30">Features & Amenities</h4>
									<div className="row">
										<div className="col-sm-12 col-md-12">
											<div className="pd-list mb10-sm">
												{bulletArray.map((bulletPoint, index) => (
													<p
														key={index}
														className="text mb10">
														<i className="fas fa-circle fz6 align-middle pe-2"></i>
														{bulletPoint.replace(/\*/g, '')}
													</p>
												))}
											</div>
										</div>
									</div>
								</div>
							)}

							<div className="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30 overflow-hidden position-relative">
								<h4 className="title fz17 mb30">Room Information</h4>
								<div>
									{roomDetails.map(
										(rooms) =>
												<div
													key={rooms.room_Sort}
													className="sp-img-content">
													{rooms?.room_Picture_URL && (
														<a
															className={`popup-img sp-img mb10 image-link preview-img-${
																rooms.room_Sort + 4
															}`}
															href={rooms.room_Picture_URL}
															target="_blank"
															rel="noreferrer">
															<img
																className="w-100"
																src={rooms.room_Picture_URL}
																alt={rooms.room_Title}
															/>
														</a>
													)}
													<div className="row">
														<div className="col-sm-12 col-lg-12 col-md-12">
															<strong>{rooms.room_Title}</strong>
															{' - '}
															<span
																className="text"
																dangerouslySetInnerHTML={{
																	__html: rooms.room_Dimensions,
																}}
															/>
														</div>
														<div className="col-sm-12 col-lg-12 col-md-12">
															{rooms.room_Description}
														</div>
													</div>
													<hr className="mb5" />
												</div>
									)}
								</div>
							</div>
							{propertyDetails?.epC_URL_1 && (
								<div className="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30 overflow-hidden position-relative">
									<h4 className="title fz17 mb30">Energy Class</h4>
									<div className="row">
										<div className="col-sm-6">
											<img
												src={propertyDetails?.epC_URL_1}
												alt=""
											/>
										</div>
										<div className="col-sm-6">
											<img
												src={propertyDetails?.epC_URL_2}
												alt=""
											/>
										</div>
									</div>
								</div>
							)}
							{propertyDetails?.floorplan_URL_1 && (
								<div className="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30 overflow-hidden position-relative">
									<h4 className="title fz17 mb30">Floor Plans</h4>
									<div className="row">
										<div className="col-md-12">
											<div className="accordion-style1 style2">
												<div
													className="accordion"
													id="accordionExample">
													{propertyDetails?.floorplan_URL_1 && (
														<div className="accordion-item active">
															<h2
																className="accordion-header"
																id="headingFirst">
																<button
																	className="accordion-button collapsed"
																	type="button"
																	data-bs-toggle="collapse"
																	data-bs-target="#collapseFirst"
																	aria-expanded="true"
																	aria-controls="collapseFirst">
																	<span className="w-100 d-md-flex align-items-center">
																		<span className="mr10-sm">First Floor</span>
																	</span>
																</button>
															</h2>
															<div
																id="collapseFirst"
																className="accordion-collapse collapse show"
																aria-labelledby="headingFirst"
																data-parent="#accordionExample">
																<div className="accordion-body text-center">
																	<img
																		className="w-100"
																		src={propertyDetails?.floorplan_URL_1}
																		alt=""
																	/>
																</div>
															</div>
														</div>
													)}
													{propertyDetails?.floorplan_URL_2 && (
														<div className="accordion-item active">
															<h2
																className="accordion-header"
																id="headingSecond">
																<button
																	className="accordion-button collapsed"
																	type="button"
																	data-bs-toggle="collapse"
																	data-bs-target="#collapseSecond"
																	aria-expanded="false"
																	aria-controls="collapseSecond">
																	<span className="w-100 d-md-flex align-items-center">
																		<span className="mr10-sm">
																			Second Floor
																		</span>
																	</span>
																</button>
															</h2>
															<div
																id="collapseSecond"
																className="accordion-collapse collapse show"
																aria-labelledby="headingSecond"
																data-parent="#accordionExample">
																<div className="accordion-body text-center">
																	<img
																		className="w-100"
																		src={propertyDetails?.floorplan_URL_2}
																		alt=""
																	/>
																</div>
															</div>
														</div>
													)}
													{propertyDetails?.floorplan_URL_3 && (
														<div className="accordion-item active">
															<h2
																className="accordion-header"
																id="headingThird">
																<button
																	className="accordion-button collapsed"
																	type="button"
																	data-bs-toggle="collapse"
																	data-bs-target="#collapseThird"
																	aria-expanded="false"
																	aria-controls="collapseThird">
																	<span className="w-100 d-md-flex align-items-center">
																		<span className="mr10-sm">Third Floor</span>
																	</span>
																</button>
															</h2>
															<div
																id="collapseThird"
																className="accordion-collapse collapse show"
																aria-labelledby="headingThird"
																data-parent="#accordionExample">
																<div className="accordion-body text-center">
																	<img
																		className="w-100"
																		src={propertyDetails?.floorplan_URL_3}
																		alt=""
																	/>
																</div>
															</div>
														</div>
													)}
													{propertyDetails?.floorplan_URL_4 && (
														<div className="accordion-item active">
															<h2
																className="accordion-header"
																id="headingFour">
																<button
																	className="accordion-button collapsed"
																	type="button"
																	data-bs-toggle="collapse"
																	data-bs-target="#collapseFour"
																	aria-expanded="false"
																	aria-controls="collapseFour">
																	<span className="w-100 d-md-flex align-items-center">
																		<span className="mr10-sm">
																			Fourth Floor
																		</span>
																	</span>
																</button>
															</h2>
															<div
																id="collapseFour"
																className="accordion-collapse collapse"
																aria-labelledby="headingFour"
																data-parent="#accordionExample">
																<div className="accordion-body text-center">
																	<img
																		className="w-100"
																		src={propertyDetails?.floorplan_URL_4}
																		alt=""
																	/>
																</div>
															</div>
														</div>
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							)}
							{propertyDetails?.video_URL && (
								<div className="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30 overflow-hidden position-relative">
									<h4 className="title fz17 mb30">Video</h4>
									<div className="row">
										<div className="col-md-12">
											<div className="property_video bdrs12 w-100">
												<a
													className="video_popup_btn mx-auto popup-img popup-youtube"
													href="https://www.youtube.com/watch?v=oqNZOOWF8qM">
													<span className="flaticon-play"></span>
												</a>
											</div>
										</div>
									</div>
								</div>
							)}
							{propertyDetails?.video_URL && (
								<div className="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30 overflow-hidden position-relative">
									<h4 className="title fz17 mb30">360° Virtual Tour</h4>
									<div className="row">
										<div className="col-md-12">
											<img
												src="images/listings/listing-single-7.jpg"
												alt=""
												className="w-100 bdrs12"
											/>
										</div>
									</div>
								</div>
							)}
						</div>
						<div className="col-lg-4">
							<div className="column">
								<div className="default-box-shadow1 bdrs12 bdr1 p30 mb30-md bgc-white position-relative">
									<h6 className="title fz17 mb30">Get More Information</h6>
									<div className="agent-single d-sm-flex align-items-center pb25">
										<div className="single-img mb30-sm">
											<img
												className="w90"
												src="images/team/agent-3.png"
												alt=""
											/>
										</div>
										<div className="single-contant ml20 ml0-xs">
											<h6 className="title mb-1">Blake & Thickbroom</h6>
											<div className="agent-meta mb10 d-md-flex align-items-center">
												<a
													className="text fz15"
													href="#">
													<i className="flaticon-call pe-1"></i>01255 688 788
												</a>
											</div>
										</div>
									</div>
									<form
										onSubmit={handleSubmit(onSubmit)}
										className="form-style1">
										<div className="row">
											<div className="col-lg-12">
												<div className="mb20">
													<label className="heading-color ff-heading fw600 mb10">
														First Name
													</label>
													<input
														{...register('firstname', { required: true })}
														className="form-control"
													/>
													{errors.firstname && (
														<span>This field is required</span>
													)}
												</div>
											</div>
											<div className="col-lg-12">
												<div className="mb20">
													<label className="heading-color ff-heading fw600 mb10">
														Last Name
													</label>
													<input
														{...register('surname', { required: true })}
														className="form-control"
													/>
													{errors.surname && (
														<span>This field is required</span>
													)}
												</div>
											</div>
											<div className="col-md-12">
												<div className="mb20">
													<label className="heading-color ff-heading fw600 mb10">
														Email
													</label>
													<input
														type="email"
														{...register('email', {
															required: 'This field is required',
															pattern: {
																value:
																	/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
																message: 'Invalid email address',
															},
														})}
														className="form-control"
													/>
													{errors.email && <span>{errors.email.message}</span>}
												</div>
											</div>
											<div className="col-md-12">
												<div className="mb10">
													<label className="heading-color ff-heading fw600 mb10">
														Message
													</label>
													<textarea
														{...register('message', { required: true })}
														value={message}
														onChange={(e) => setMessage(e.target.value)}
														className="form-control"
														cols={30}
														rows={8}
													/>
													{errors.message && (
														<span>This field is required</span>
													)}
												</div>
											</div>
											<div className="col-md-12">
												<div className="d-grid">
													<button
														type="submit"
														className="ud-btn btn-thm">
														Submit <i className="fal fa-arrow-right-long"></i>
													</button>
												</div>
												{errormessage && (
													<div
														className="alert alart_style_four fade show mb20"
														role="alert">
														{errormessage}
													</div>
												)}
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default PropertyDetails;
